import {actionCreators as personActions} from './PersonStore';
import {actionCreators as teamActions} from './TeamStore';
import {actionCreators as plannedItemActions} from './PlannedItemStore';
import {actionCreators as documentActions} from './DocumentStore';
import {actionCreators as resourceActions} from './ResourceStore';
import {actionCreators as eventActions} from './EventStore';
import {actionCreators as drActions} from "./DrNewsStore";
import {actionCreators as winterDutyActions} from './WinterDutyStore';
import {actionCreators as slideActions} from './SlideStore';
import {actionCreators as freeTextActions} from './FreeTextStore';


export const reducerName = 'appStore';

const APP_REFRESH_EVERYTHING = 'APP_REFRESH_EVERYTHING';


export const actionCreators = {
    refresh: () => async(dispatch) => {
        dispatch({type: APP_REFRESH_EVERYTHING});
        dispatch(personActions.getAllPersons());
        dispatch(teamActions.getAll());
        dispatch(plannedItemActions.getAllColors());
        dispatch(plannedItemActions.getAllPlans());
        dispatch(documentActions.getAll());
        dispatch(resourceActions.getAllUnits());
        dispatch(resourceActions.getAllBookings());
        dispatch(eventActions.getFutureEvents());
        dispatch(drActions.getNews());
        dispatch(slideActions.getSlideShow("10010"));
        dispatch(freeTextActions.get());

    },
    refreshSlow: () => async(dispatch) => {
        dispatch(winterDutyActions.getAllVintermanToday());
    }
};
