import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';
import * as TeamStore from '../../store/TeamStore';
import Unit from './Unit';

const TeamChooser = props => {
    const showMenu = props.resState.showTeamMenu;
    if(!showMenu) return null;

    const units = props.resState.units;

    const teams = props.teamState.teams;

    const administration = teams.find(x => x.id === 46);
    const pladsmen = teams.find(x => x.id === 45);
    const workshop = teams.find(x => x.id === 44);
    const recycle = teams.find(x => x.id === 43);
    const rats = teams.find(x => x.id === 42);
    const sports = teams.find(x => x.id === 41);
    const animal = teams.find(x => x.id === 40);
    const garden6 = teams.find(x => x.id === 38);
    const garden5 = teams.find(x => x.id === 37);
    const garden4 = teams.find(x => x.id === 36);
    const garden2 = teams.find(x => x.id === 35);
    const garden1 = teams.find(x => x.id === 34);
    const buildings = teams.find(x => x.id === 32);

    const unitBooking = units.find(x => x.id === props.resState.createBooking.unitId);
    const createBooking = props.resState.createBooking;


    const renderTeam = (team) => {
        return(
            <div key={team.id} className={`team${createBooking.teamId === team.id ? '' : ' active'}`} onClick={() => props.resourceActions.setBookingField('teamId', team.id)}>
                <div className='text'>{team.description}</div>
            </div>
        )
    };

    const renderRow = (mainTeam, teams) => {
        return(
            <div className='team-row'>
                <h2>{mainTeam.name}</h2>
                {renderTeam(mainTeam)}
                {teams && teams.map(x => renderTeam(x))}
            </div>
        )
    };

    return(
        <div className='dialog-overlay' onClick={() => props.resourceActions.showTeamMenu(false)}>
            <div className='dialog teamchooser' onClick={(e) => e.stopPropagation()}>
                <div className='unit-container'>
                    <Unit unit={unitBooking} hideText noMenu hideName/>
                    <div className='clearfix'/>
                </div>
                <h1>Hvem reserverer "{unitBooking.name}"?</h1>
                {renderRow(garden1, [garden2, garden4, garden5, garden6])}
                {renderRow(administration, [animal, sports, rats])}
                {renderRow(buildings)}
                {renderRow(workshop, [pladsmen])}
                {renderRow(recycle)}
                <div className={`dialog-btn${createBooking.teamId ? '' : ' disabled'}`} onClick={
                    () => {props.resourceActions.showTimeMenu(false);
                    props.resourceActions.showTeamMenu(true);
                    props.resourceActions.setTeamAndBook(createBooking.teamId);
                    }}
                >Done</div>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        teamState: state[TeamStore.reducerName],
        resState: state[ResourceStore.reducerName]
    }),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(TeamChooser);