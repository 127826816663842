import React,{Component} from  'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {EmptyDay} from './../planning/Day';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

class ResourceCalendarLine extends Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const plansDifferent = JSON.stringify(this.props.plans) !== JSON.stringify(nextProps.plans);
        const daysDifferent = this.props.days[0] !== nextProps.days[0] ||
            this.props.days.length !== nextProps.days.length;

        return plansDifferent || daysDifferent;
    }

    render(){
        const props = this.props;
        const unit = props.unit;
        const granularity = props.granularity;
        const days = props.days;
        const dayWidth = props.dayWidth;
        const plans = props.plans;

        const hourComponents = () => days.map((d,i) => {
            return plans.map((p,i) => {
                const startTime = moment(p.startTime).diff(props.startTime, 'minutes') / 60 * dayWidth;
                const duration = moment(p.endTime).diff(p.startTime, 'minutes') / 60;
                if(duration > 0){
                    return <div key={i} className='plan' style={{left: `${startTime}%`, backgroundColor: 'red', width: `${dayWidth*duration}%`}}>{p.teamId ? p.team.description : p.person.name}</div>
                }
                return null;
            });
        });


        const dayComponents = () => {
            const start = new Date(props.startTime);
            return plans.map((p,i) => {
                const planStart = new Date(p.startTime);
                const planEnd = new Date(p.endTime);
                const startTime = Math.ceil(((planStart - start) / _MS_PER_DAY) - 1) * dayWidth;
                const duration = Math.ceil((planEnd - planStart) / _MS_PER_DAY);
                if(duration > 0){
                    return <div key={i} className='plan' style={{left: `${startTime}%`, backgroundColor: 'red', width: `${dayWidth*duration}%`}}>{p.teamId ? p.team.name : p.person.name}</div>
                }
                return null;
            });
        };

        const grid = days.map((d,i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={false}/>;
        });
        const hourGrid = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map((d,i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={false}/>;
        });


        return (
            <div className="row" onClick={props.onClick || null}>
                <div className='plans'>
                    {granularity === 'hour' ? hourComponents() : dayComponents()}
                </div>
                <div className='cell-grid'>
                    {granularity === 'hour' ? hourGrid : grid}
                </div>
                <div className='name'>{unit.name}</div>
            </div>
        );
    };
}

ResourceCalendarLine.propTypes = {
    unit: PropTypes.object.isRequired,
    granularity: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    plans: PropTypes.array,
    startTime: PropTypes.any.isRequired
};

export default ResourceCalendarLine;