import React from 'react';
import Person from './Person';
import '../../css/Team.css';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

const Team = props => {
    const team = props.team;
    const members = team.members;

    const renderPersonComponents = (members) => {
        return members.map((member, i) => {
            const p = props.personState.persons[member.id];
            return <Person key={i} person={p} />
        });
    };

    let teamLeader;
    if(team.teamLeader && !props.hideLeader){
        const p = props.personState.persons[team.teamLeader.id];
        teamLeader = <Person person={p}/>;
    }
    const style = {
        width: props.width + '%',
        height: props.height ? `${props.height}%` : ''
    };

    //subteams?
    const renderSubTeams = () => {
        if(!props.subTeams) return;
        return props.subTeams.map((x,i) => {
            return(
                <div key={i} className='subteam'>
                    <div className="divider" />
                    <h4 className="team-description">{x.description}</h4>
                    <div className="members">
                        {renderPersonComponents(x.members)}
                    </div>
                </div>
            )
        })
    };

    return (
        <div className={`team${props.className ? ` ${props.className}` : ''}`} style={style}>
            <div className="inner">
                <h3 className="team-name">{team.name}</h3>
                <div className="divider" />
                <h4 className="team-description">{team.description}</h4>
                <div className="teamleader">{teamLeader}</div>
                <div className="members">
                    {renderPersonComponents(members)}
                </div>
                {renderSubTeams()}
            </div>
        </div>
    );
};

Team.propTypes = {
    team: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number,
    subTeams: PropTypes.array,
    className: PropTypes.string,
    hideLeader: PropTypes.bool
};

export default connect(
    state => ({personState: state.personState}),
)(Team);
