import React from 'react';
import Team from './Team'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import connect from 'react-redux/es/connect/connect';
import * as ResourceStore from '../../store/ResourceStore';
import moment from 'moment';

const CustomTeamList = props => {
    const units = props.state[ResourceStore.reducerName].units;
    const bookings = props.state[ResourceStore.reducerName].bookings;
    const now = moment();
    const currentBookings = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const teams = props.state.teamState.teams;
    if(teams.length < 1) return null;

    const addUnits = team => {
        team.units = currentBookings.reduce((result,b) => {
            if(b.teamId === team.id){
                result.push(units.find(u => u.id === b.unitId));
            }
            return result;
        }, []);
    };

    const administration = teams.find(x => x.id === 46);
    addUnits(administration);

    const pladsmen = teams.find(x => x.id === 45);
    addUnits(pladsmen);

    const workshop = teams.find(x => x.id === 44);
    addUnits(workshop);

    const recycle = teams.find(x => x.id === 43);
    addUnits(recycle);

    const rats = teams.find(x => x.id === 42);
    addUnits(rats);

    const sports = teams.find(x => x.id === 41);
    addUnits(sports);

    const animal = teams.find(x => x.id === 40);
    addUnits(animal);

    const garden4 = teams.find(x => x.id === 38);
    addUnits(garden4);

    const garden3 = teams.find(x => x.id === 37);
    addUnits(garden3);

    const garden2 = teams.find(x => x.id === 35);
    addUnits(garden2);

    const garden1 = teams.find(x => x.id === 34);
    addUnits(garden1);

    const buildings = teams.find(x => x.id === 32);
    addUnits(buildings);

    const vejbelysning = teams.find(x => x.id === 74);
    addUnits(vejbelysning);

    return (
        <div className="team-list">

            <div style={{width: '25%', height: '100%', float: 'left'}}>
                <Team team={garden1} width={100} height={25} className='garden-team' />
                <Team team={garden2} width={100} height={25} className='garden-team' />
                <Team team={garden3} width={100} height={25} className='garden-team' />
                <Team team={garden4} width={100} height={25} className='garden-team' />
            </div>
            
            <Team team={administration} width={25} height={75}  subTeams={[animal, sports, rats, ]}/>

            <div style={{width: '25%', height: '100%', float: 'left'}}>
                <Team team={buildings} width={100} height={37.5} hideLeader />
                <Team team={recycle} width={100} height={37.5} />
            </div>

            <Team team={workshop} width={25} height={50} subTeams={[pladsmen]} hideLeader />

            <Team team={vejbelysning} width={25} height={25} hideLeader />



            <ColorLegend />
        </div>
    );
};

export default connect(
    state => ({state: state})
)(CustomTeamList);